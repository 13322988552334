<template>
  <addField
    path="serve.php?f=configuration&f2=subscriber"
    :additionalAttributes="additionalAttributes"
    :dialogAttributes="currentTable['editDialogAttributes']"
  ><p>{{ row[header].name }}</p></addField
  >
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  components: { addField },

  props: ["header", "row", "currentTable"],
  computed: {
    subscriberName() {
      return this.row[this.header].name;
    },

    additionalAttributes: function () {
      let additionalAttributes = this.currentTable["editAdditionalAttributes"];
      additionalAttributes = {
        n_id: this.row[this.header].n_id,
        n_id_key: this.row[this.header].n_id_key,
        function: "getInsertedFieldsEdit",
        ...additionalAttributes,
      };
      return additionalAttributes;
    },
  },
};
</script>